import {
    MDBContainer,
  MDBInput,
  MDBCheckbox,
  MDBBtn,
  MDBIcon
  }
  from 'mdb-react-ui-kit';

  import { Row, Col, Container } from 'react-bootstrap';
  import headerImg2 from "../assets/img/header-img2.png";
  import { FaUserAstronaut } from "react-icons/fa";

  import FirebaseBD from '../credentials';
  import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
  const auth = getAuth(FirebaseBD.App);

export const Login = () => {

    const authentication = async(e) => {
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;
        console.log(email);
        try {
            await signInWithEmailAndPassword(auth,email,password);
            document.getElementById('redirect').click();
        } catch (error) {
            alert("Los datos NO coinciden.");
            e.target.email.value = "";
            e.target.password.value = "";
        }
        
    }

    return (
        <section className='paddingTopNav'>
            <Container>
                <Row>
                    <Col xs={12} md={6} >
                        <div className='p-3 my-5 '>
                            <div className='text-center py-3'>  
                                <h2>Campus de Formación</h2>
                                <span>Evalua tus competencias realizando un simulacro tipo icfes.</span>
                            </div>
                            <a href='/dashbaoard' id='redirect' ></a>
                            <form className='loginForm' onSubmit={authentication}>
                                <div>
                                    <input  type='email' placeholder='Email' id='email' />
                                </div>
                                <div>
                                    <input type='password' label='Password' placeholder='Contraseña' id='password' />
                                </div>
                                <div>
                                    <button className='buttonLogin'><FaUserAstronaut /> Ingresar</button>
                                </div>
                            </form>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <img src={headerImg2} alt="Header img" />
                    </Col>
                </Row>
            </Container>
        </section>
    );
}