import { NavBar } from "../components/NavBar";
import { Banner } from "../components/Banner";
import { Skills } from "../components/Skills";
import { Method } from "../components/Method";
import { Plains } from "../components/Plains";
import { Footer } from "../components/Footer";

export const HomePage = (props) => {
    return (
      <div className="App">
        <NavBar option={false} />
        <Banner />
        <Skills />
        <Method />
        <Plains />
        <Footer />
      </div>
    )
}