import { Login } from "../components/Login";
import { NavBar } from "../components/NavBar";

export const LoginPage = () => {
    return (
        <div>
           <NavBar option={true} />
            <Login />
        </div>
    );
}