// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAIRtDZxi5prqPMJbLZVXWz7uN2JdowBWY",
  authDomain: "intesa-preicfes.firebaseapp.com",
  projectId: "intesa-preicfes",
  storageBucket: "intesa-preicfes.appspot.com",
  messagingSenderId: "164369185624",
  appId: "1:164369185624:web:dec7de323ac9c73afb832d",
  measurementId: "G-C7MCY3QPSJ"
};

// Initialize Firebase
const appFirebase = initializeApp(firebaseConfig);
const db = getFirestore(appFirebase);
const analytics = getAnalytics(appFirebase);

const FirebaseBD = {
    App : appFirebase,
    Database : db
}
export default FirebaseBD;