import {collection, getDocs, getDoc, deleteDoc} from 'firebase/firestore';
import FirebaseBD from '../credentials';
import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { Container } from 'react-bootstrap';

export const ListUser = (props) => {

    const [users, setUsers] = useState([]);

    const usersCollection = collection(FirebaseBD.Database, 'usuarios');

    const getUsers = async () => {
        const data = await getDocs(usersCollection);
    
        setUsers(data.docs.map( (doc) => ({...doc.data(), id:doc.id }) ));
        console.log(users);
    }

    useEffect(() => {
        getUsers();
    }, [])

    return (
        <div className='ListUser'>
            <Container>
                <div>
                    <h2 className='my-5'>👋Bienvenido {props.email}!</h2>
                </div>
                <table>
                    <thead>
                        <tr>
                        <th>Nombre Completo</th>
                        <th>Username</th>
                        <th>Contraseña</th>
                        <th>Escuela</th>
                        <th>Token</th>
                        </tr>
                    </thead>
                    <tbody>
                    { users.map((user) => (
                    <tr>
                            <td>{user.names}</td>
                            <td>{user.username}</td>
                            <td>{user.password}</td>
                            <td>{user.school}</td>
                            <td>{user.token}</td>
                    </tr>
                    
                    )) }
                    </tbody>
                </table>
            </Container>   
        </div>
    );
    
}