import { ListUser } from "../auth/ListUser"
import { NavBar } from "../auth/NavBar"


export const HomePageAuth = (props) => {
    return (
      <div className="App">
        <NavBar option={false} />
        <ListUser email={props.email} />
      </div>
    )
}