import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from './components/NavBar';
import { Banner } from './components/Banner';
import { Skills } from './components/Skills';
import { Method } from './components/Method';
import { Plains } from './components/Plains';
import { Footer } from './components/Footer';
import { HomePage } from './pages/HomePage';
import { Routes, Route } from "react-router-dom";
import { HomePageAuth } from './pages/HomePageAuth';

//ENRUTADOR -- React Router

import './App.css';

/*IMPORTANDO MODULOS DE FOREBSA */
import { LoginPage } from './pages/LoginPage';
import FirebaseBD from './credentials';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import { useState } from 'react';
const auth = getAuth(FirebaseBD.App)

function App() {

  const [user, setUser] = useState(null);

  /* Conocer el estado de la Autenticacion */
  onAuthStateChanged(auth, (userFirebase) => {
    if(userFirebase){
      /* Si detectas que alguien esta logueado */
      setUser(userFirebase);
    }else{
      setUser(null);
    }
  })

  return (
    <div className="App">
      { user ? 
      <div>
          <Routes>
            <Route path="/dashboard" element={<HomePageAuth option={false} email={user.email} />} />
            <Route path="*" element={<HomePageAuth option={false} email={user.email} />} />
        </Routes>  
      </div>
      :
      <div>
        <Routes>
            <Route path="/" element={<HomePage option={false}  />} />
            <Route path="/login" element={<LoginPage option={true} />} />
            <Route path="*" element={<HomePage option={false} />} />
        </Routes>  
      </div>
      }
       
    </div>
  );
}

export default App;
